// Reset
html {
  box-sizing: border-box;
  *,
  *:after,
  *:before {
    padding: 0;
    margin: 0;
    box-sizing: inherit; } }
img,
video {
  max-width: 100%;
  height: auto; }
ul {
  li {
    list-style: none; } }

// Mixins
// =============================================================================
// Función para agregar Flex
// =============================================================================
$justify-content: center !default;
$flex-wrap: nowrap !default;
$flex-direction: row !default;
$align-content: center !default;
$align-items: center !default;
@mixin flex($justify-content,$flex-wrap, $flex-direction,$align-content,$align-items) {
  display: flex;
  justify-content: $justify-content;
  flex-wrap: $flex-wrap;
  flex-direction: $flex-direction;
  align-content: $align-content;
  align-items: $align-items; }
//+flex(flex-start,nowrap,column,center,stretch)

// =============================================================================
// Fuentes
// =============================================================================

//función usada para agergar fuentes
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace); }
	@return $string; }

//función para agregar fuentes
//+font-face($sspro-black, fonts/avenir, Avenir-Black, 900, normal)
@mixin font-face($name, $path, $filename, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;
	$extmods: (eot: "?", svg: "#" + str-replace($filename, " ", "_"));
	$formats: (otf: "opentype",	ttf: "truetype");
	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "/" + $filename + "." + $extmod)) format(quote($format)), comma); }
	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
		font-display: swap; } }

$fuente1: 'Gilmer';
@include font-face($fuente1, "../../fonts", GilmerRegular, normal, normal);
@include font-face($fuente1, "../../fonts", GilmerLight, 300, normal);
@include font-face($fuente1, "../../fonts", GilmerBold, bold, normal);

$fuente: 'Public Sans';
@include font-face($fuente, "../../fonts", PublicSans-Regular, normal, normal);


$font_ico: 'icomoon';

// The default font size for html is 16px
$base-size: 16;
//Function to convert px value into rem
//You can replace rem with any other value you want (rem is most preferred value for web)
@function size($target, $context: $base-size) {
	@return ($target / $context) * 1rem; }
// Let's use the size whenever you want
// margin: size(20)

/* line-height: calc(margenExtra + alturaDinámica + margenExtra) */
// calc(2px+2ex+2px)

// =============================================================================
// Colores
// =============================================================================

$color1: #64686C;
$color2: #0E3FF2;
$color3: #00FFCC;
$color4: #132458;
$white: #FFFFFF;
$black: #0E1322;
$background: #E5E5E5;
$ctexto: #64686C;

// =============================================================================
// Responsive
// =============================================================================
$breakpoint1: 768px;
$breakpoint2: 1022px;
$breakpoint3: 1200px;

// Admin Bar
.admin-bar .site-container {
  padding-top: 32px; }

@media only screen and (max-width: 782px) {
  .admin-bar .site-container {
    padding-top: 46px; } }

// =============================================================================
// Css Generales
// =============================================================================

// Wrap-width / full-width
$wrap: size(1200);
$full-width: 100%;
.full {
	max-width: $full-width;
	margin: 0 auto; }
.wrap {
	margin: 0 auto;
	max-width: $wrap; }
body {
	font-family: $fuente;
	font-weight: 300;
	font-size: size(12);
	line-height: size(26);
	color: $ctexto;
	@media (min-width: $breakpoint1) {
		font-size: size(14);
		line-height: size(30); } }
strong {
	font-weight: 600; }

/* !-------- WORDPRESS CLASSES ------------------- */

/* !-- WP WYSIWYG Editor Styles -- */
.entry-content img {
	margin: 0 0 1.5em 0; }

.alignleft, img.alignleft {
	margin-right: 1.5em;
	display: inline;
	float: left; }

.alignright, img.alignright {
	margin-left: 1.5em;
	display: inline;
	float: right; }

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
	clear: both; }

.alignnone, img.alignnone {
	/* not sure about this one */ }

.wp-caption {
	margin-bottom: 1.5em;
	text-align: center;
	padding-top: 5px; }

.wp-caption img {
	border: 0 none;
	padding: 0;
	margin: 0; }

.wp-caption p.wp-caption-text {
	line-height: 1.5;
	font-size: 10px;
	margin: 0; }

.wp-smiley {
	margin: 0 !important;
	max-height: 1em; }

blockquote.left {
	margin-right: 20px;
	text-align: right;
	margin-left: 0;
	width: 33%;
	float: left; }

blockquote.right {
	margin-left: 20px;
	text-align: left;
	margin-right: 0;
	width: 33%;
	float: right; }

